<template>
  <b-overlay :show="loading">
    <!-- <form-filter-export @onExport="exportpengirimanBarang"></form-filter-export> -->
    <b-card>
      <!-- <b-row v-if="allowCreate()">
        <b-col class="my-1">
          <b-button
            v-b-modal.modal-tambah
            variant="primary"
            v-if="allowCreate() && myGudang"
            @click="add()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Penyimpanan
          </b-button>
          <b-button v-if="allowCreate()" variant="primary" class="ml-1"
                @click.prevent="$router.push({ name: 'route-diskon' })">
                <feather-icon icon="SettingsIcon" class="mr-50" />Atur Voucher Diskon </b-button>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group
            class="mb-0"
            label-size="sm"
            :label="$t('Per page')"
            label-for="perPage"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="10" sm="8" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Tekan enter untuk cari"
                @keydown.enter.prevent="getpenyimpananBarangs()"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="
                    filter = '';
                    getpenyimpananBarangs();
                  "
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            small
            striped
            hover
            responsive
            :per-page="perPage"
            :items="penyimpananBarangs"
            :fields="fieldsasli"
          >
            <!-- <template #thead-top="data">
              <b-tr>
                <b-th colspan="4" style="background-color: transparent"></b-th>
                <b-th variant="secondary">Type 1</b-th>
                <b-th colspan="4" style="" class="text-center"
                  >Lokasi Penyimpanan</b-th
                >
                <b-th ></b-th>
              </b-tr>
            </template> -->
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
            <template #cell(kode)="{ item }">
              {{ item.barang ? item.barang.kode : "-" }}<br>
              <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
            </template>
            <template #cell(nama)="{ item }">
              {{ item.barang ? item.barang.varian : "-" }}<br>
              <small>{{item.barang.kategori.kategori}}</small> /
                      <small>
                        {{ item.barang ? item.barang.nama : "-" }}
                      </small> <br>
                      <small>{{$t('Room')}} : </small>
                      <small>{{ item.blok ? item.blok.blok : "-" }}</small>
                        <small>{{ item.palet ? item.palet.palet : "-" }}</small>
                        <small>{{ item.rak ? item.rak.rak : "-" }}</small>
                        <small>{{ item.laci ? item.laci.laci : "-" }}</small>

            </template>
            <template #cell(varian)="{ item }">
              {{ item.barang ? item.barang.varian : "-" }}
            </template>
            <template #cell(blok)="{ item }">
              {{ item.blok ? item.blok.blok : "-" }}
            </template>
            <template #cell(palet)="{ item }">
              {{ item.palet ? item.palet.palet : "-" }}
            </template>
            <template #cell(rak)="{ item }">
              {{ item.rak ? item.rak.rak : "-" }}
            </template>
            <template #cell(laci)="{ item }">
              {{ item.laci ? item.laci.laci : "-" }}
            </template>
            <template #cell(jumlah)="{ item }">
              <b class="text-success">{{ item.jumlah ? item.jumlah : 0 }}</b><br>
              <small>
                ( {{ item.konversi_ket == null ? 0 : item.konversi_ket }} )
              </small>
            </template>
            <template #cell(satuan)="{ item }">
              <!-- {{ item.barang.satuan ? item.barang.satuan.satuan : '-' }} -->
              {{ item.konversi_ket }}
            </template>

            <template #cell(selesai)="data">
              <b-badge variant="primary">
                {{
                  data.item.status_ket
                    ? data.item.status_ket.toUpperCase()
                    : "-"
                }}
              </b-badge>
            </template>
            <template #cell(po)="{ item }">
              {{ item.po ? item.po.no : "po kosong" }}
            </template>

            <template #cell(tanggal_pengiriman)="{ item }">
              {{ humanDate(item.tanggal) }}
            </template>

            <template #cell(tanggal_invoice)="{ item }">
              {{ humanDate(item.tanggal_invoice) }}
            </template>

            <template #cell(publish)="{ item }">
              <section class="flex item-center">
                <b-form-checkbox
                  v-model="item.is_published"
                  :value="true"
                  :unchecked-value="false"
                  @change="togglePublish(item)"
                  switch
                >
                </b-form-checkbox>
              </section>
            </template>
            <template #cell(actions)="{ item }">
              <section class="flex item-center">
                <b-button
                  title="'Detail'"
                  size="sm"
                  @click="$router.push(`/owner/pengiriman/detail/${item.id}`)"
                  class="mr-1"
                  variant="outline-primary"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-if="
                    (allowUpdate() && item.status == 1) ||
                    (allowUpdate() && !isAdminGudang)
                  "
                  title="'Batalkan Pengiriman'"
                  size="sm"
                  @click="batal(item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="XCircleIcon" />
                </b-button>
                <b-button
                  v-if="
                    (allowUpdate() && item.status == 0) ||
                    (allowUpdate() && !isAdminGudang)
                  "
                  title="'Ubah'"
                  size="sm"
                  @click="$router.push(`/owner/pengiriman/edit/${item.id}`)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- v-if="penyimpananBarangs.selesai = 1" -->
                <b-button
                  v-if="
                    (allowDelete() && item.status == 0) ||
                    (allowUpdate() && !isAdminGudang)
                  "
                  title="'Hapus'"
                  size="sm"
                  @click="remove(item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </section>
            </template>
            <template #cell(actionsCabang)="{ item }">
              <section class="flex item-center">
                <b-button
                  title="'Ubah Barang'"
                  size="sm"
                  @click="ubahbrg(item)"
                  class="mr-1"
                  variant="primary"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  title="'Ubah Lokasi Penyimpanan'"
                  size="sm"
                  @click="ubahpeny(item)"
                  class=""
                  variant="info"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
              </section>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      v-if="allowCreate() || allowUpdate()"
      v-model="showModal"
      id="modal-"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      title="Form "
      size="xl"
    >
      <validation-observer ref="form">
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19" />
          <h6 class="ml-50 mb-2">Penyimpanan Barang</h6>
        </div>
        <b-row>
          <!-- List barang di penerimaan -->
          <b-col sm="12" md="8" lg="8" xl="8">
            <b-card title="Pilih Barang">
              <b-row>
                <b-col sm="6" md="6" lg="6">
                  <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"
                      >Per page</label
                    >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6" lg="6">
                  <b-form-group
                    :label="$t('Sort')"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="sortBySelect"
                        v-model="sortBy"
                        :options="sortOptions"
                        class="w-75"
                      >
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select
                        v-model="sortDesc"
                        size="sm"
                        :disabled="!sortBy"
                        class="w-25"
                      >
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="12">
                  <b-form-group>
                    <label for="Cari Barang">Cari Barang</label>
                    <b-input-group>
                      <b-form-input
                        v-model="caribarang"
                        placeholder="Tekan enter untuk cari"
                        @keydown.enter.prevent="getDataBarangs()"
                      ></b-form-input>
                      <!-- <b-form-input placeholder="Button on right" /> -->
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="getDataBarangs()"
                        >
                          Cari
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="12">
                  <b-table
                    v-if="loaded"
                    striped
                    small
                    hover
                    responsive
                    :per-page="perPageBarangs"
                    :items="dataBarangs"
                    :fields="fieldsBarangs"
                    :sort-by.sync="sortByBarangs"
                    :sort-desc.sync="sortDescBarangs"
                    :sort-direction="sortDirectionBarangs"
                    :filter-included-fields="filterOnBarangs"
                    @filtered="onFilteredBarangs"
                  >
                    <template #cell(penyimpanan)="data">
                      <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                      <b-form-checkbox
                        :value="data.item"
                        v-model="pilihbarang"
                      />
                    </template>
                    <template #cell(no)="row">
                      <strong class="text-center">
                        {{ row.index + 1 }}
                      </strong>
                    </template>
                    <template #cell(kode)="{ item }">
                      {{ item ? item.kode : "-" }}
                    </template>
                    <template #cell(nama)="{ item }">
                      {{ item ? item.varian : "-" }}<br>
                      <small>
                        {{ item ? item.nama : "-" }}
                      </small>
                    </template>
                    <template #cell(blok)="{ item }">
                      {{ item.blok ? item.blok.blok : "-" }}
                    </template>
                    <template #cell(palet)="{ item }">
                      {{ item.palet ? item.palet.palet : "-" }}
                    </template>
                    <template #cell(rak)="{ item }">
                      {{ item.rak ? item.rak.rak : "-" }}
                    </template>
                    <template #cell(laci)="{ item }">
                      {{ item.laci ? item.laci.laci : "-" }}
                    </template>
                    <template #cell(jumlah)="{ item }">
                      {{ item.jumlah ? item.jumlah : "-" }}
                    </template>
                    <template #cell(satuan)="{ item }">
                      <!-- {{ item.satuan ? item.satuan.satuan : '-' }} -->
                      {{ item.konversi_ket }}
                    </template>
                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-pagination
                    v-model="currentPageBarangs"
                    :total-rows="totalRowsBarangs"
                    :per-page="perPageBarangs"
                    align="center"
                    size="sm"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- Penempatan gudang -->
          <b-col sm="12" md="4" lg="4" xl="4">
            <b-card title="Tentukan Penyimpanan">
              <form>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="gudang"
                    rules="required"
                  >
                    <label for="">Pilih Gudang</label>
                    <b-form-select
                      :options="dataGudang"
                      v-model="form.gudang_id"
                      :required="!form.gudang_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="blok"
                    rules="required"
                  >
                    <label for="">Pilih Blok</label>
                    <b-form-select
                      :options="blok_id"
                      v-model="form.blok_id"
                      :required="!form.blok_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Pallet</label>
                  <!-- <b-form-select :options="palet_id" v-model="form.palet_id" :required="!form.palet_id"
                    :reduce="option => option.value" label="text" />-->
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Rak (optional)</label>
                  <b-form-select
                    v-model="form.rak_id"
                    :options="rak_id"
                    :reduce="(option) => option.value"
                    label="text"
                    class="mb-2"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Laci (optional)</label>
                  <b-form-select
                    v-model="form.laci_id"
                    :options="laci_id"
                    :reduce="(option) => option.value"
                    label="text"
                    class="mb-2"
                  />
                </b-form-group>
                <b-form-group>
                  <b-button @click.prevent="addBarang" variant="primary"
                    >Add</b-button
                  >
                </b-form-group>
              </form>
            </b-card>
          </b-col>
          <!-- Barang yang sudah disimpan -->
          <b-col cols="12">
            <b-card>
              <b-table
                :fields="fieldsAddedItems"
                bordered
                striped
                responsive
                :items="addedItems"
              >
                <template #cell(no)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(lokasi)="{ item }">
                  <strong>
                    {{ item.lokasi }}
                  </strong>
                </template>
                <template #cell(action)="row">
                  <b-link
                    class="text-danger"
                    @click.prevent="addedItems.splice(row.index, 1)"
                  >
                    <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                  </b-link>
                </template>
              </b-table>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click.prevent="submit">
                  Simpan
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <b-modal
      v-if="allowCreate() || allowUpdate()"
      v-model="showModalBrg"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      :title="'Ubah Barang Penyimpanan'"
      size="lg"
    >
      <b-col sm="12" md="12" lg="12">
        {{ this.ubah ? (this.ubah.barang ? this.ubah.barang.nama : 0) : 0 }}
        <b-card
          border-variant="primary"
          title="Barang"
          bg-variant="transparent"
          class="shadow-none"
        >
          <b-row>
            <b-col sm="12" md="12" lg="12">
              <b-form-group>
                <label for="Cari Barang">Cari Barang</label>
                <b-input-group>
                  <b-form-input
                    v-model="caribarang"
                    placeholder="Tekan enter untuk cari"
                    @keydown.enter.prevent="getDataBarangs()"
                  ></b-form-input>
                  <!-- <b-form-input placeholder="Button on right" /> -->
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="getDataBarangs()"
                    >
                      Cari
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="12">
              <b-table
                v-if="loaded"
                striped
                small
                hover
                responsive
                :per-page="perPageBarangs"
                :items="dataBarangs"
                :fields="fieldsBarangs"
                :sort-by.sync="sortByBarangs"
                :sort-desc.sync="sortDescBarangs"
                :sort-direction="sortDirectionBarangs"
                :filter-included-fields="filterOnBarangs"
                @filtered="onFilteredBarangs"
              >
                <template #cell(penyimpanan)="data">
                  <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                  <b-form-checkbox :value="data.item" v-model="pilihbarang2" />
                </template>
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ row.index + 1 }}
                  </strong>
                </template>
                <template #cell(kode)="{ item }">
                  {{ item.kode ? item.kode : "-" }}
                </template>
                <template #cell(harga_dasar)="{ item }">
                  {{ item.harga_dasar ? formatRupiah(item.harga_dasar) : "-" }}
                </template>
                <template #cell(satuan)="{ item }">
                  {{ item.satuan ? item.satuan.satuan : "-" }}
                </template>
              </b-table>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click.prevent="submitbrg">
                  Ubah Barang Penyimpanan
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-modal>
    <b-modal
      v-if="allowCreate() || allowUpdate()"
      v-model="showModalPen"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      :title="'Ubah Lokasi Penyimpanan'"
      size="lg"
    >
      <b-col sm="12" md="12" lg="12">
        {{
          this.ubahpen
            ? this.ubahpen.barang
              ? this.ubahpen.barang.nama
              : 0
            : 0
        }}
        <!-- <b-col sm="6" md="6" lg="6" xl="6"> -->
        <b-card title="Buat Penyimpanan Baru" border-variant="primary">
          <validation-observer ref="form2">
            <form>
              <b-row>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="blok"
                      rules="required"
                    >
                      <label for="">Pilih Blok</label>
                      <b-form-select
                        :options="blok_id"
                        v-model="ubahpen.blok_id"
                        :required="!ubahpen.blok_id"
                        :reduce="(option) => option.value"
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <label for="">Pilih Palet</label>
                    <b-form-select
                      :options="palet_id"
                      v-model="ubahpen.palet_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <label for="">Pilih Rak (optional)</label>
                    <b-form-select
                      v-model="ubahpen.rak_id"
                      :options="rak_id"
                      :reduce="(option) => option.value"
                      label="text"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <label for="">Pilih Laci (optional)</label>
                    <b-form-select
                      v-model="ubahpen.laci_id"
                      :options="laci_id"
                      :reduce="(option) => option.value"
                      label="text"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </form>
          </validation-observer>
        </b-card>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click.prevent="submitpen">
            Ubah Lokasi Penyimpanan
          </b-button>
        </div>
      </b-col>
      <!-- </b-col> -->
    </b-modal>
  </b-overlay>
</template>
<script>
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BLink,
  BOverlay,
  BModal,
  BTable,
  VBTooltip,
  BTooltip,
  BAvatar,
  BCard,
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTextarea,
} from "bootstrap-vue";
// import mixin from './mixin'
export default {
  // mixins: [mixin],
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BLink,
    BForm,
    FormFilterExport,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationObserver,
    ValidationProvider,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BFormSelect,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data: () => ({
    dataBarangs: null,
    caribarang: null,
    pageOptionsBarangs: [10, 20, 50],
    sortByBarangs: null,
    sortDescBarangs: false,
    sortDirectionBarangs: "asc",
    filterBarangs: null,
    filterOnBarangs: [],
    perPageBarangs: 10,
    totalRowsBarangs: 1,
    currentPageBarangs: 1,
    loaded: true,
    showModal: false,
    showModalBrg: false,
    pilihbarang2: null,
    ubah: null,
    ubahpen: {
      blok_id: null,
      palet_id: null,
      rak_id: null,
      laci_id: null,
    },
    showModalPen: false,
    //Penyimpanan
    blok_id: [],
    palet_id: [],
    rak_id: [],
    laci_id: [],
    //List penyimpanan yang akan di simpan
    pilihbarang: null,
    addedItems: [],
    fieldsAddedItems: [
      { key: "no", label: "No" },
      { key: "barang", label: "Barang" },
      { key: "lokasi", label: "Lokasi" },
      { key: "action", label: "#" },
    ],
    required,
    password,
    email,
    confirmed,
    params: {
      from_gudang_id: null,
    },
    loading: false,
    id: null,
    form: {},
    dataGudang: [],
    otherGudangs: [],
    perPage: 10,
    pageOptions: [5, 10, 50, 100],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    penyimpananBarangs: [],
    directive: {
      "b-tooltip": VBTooltip,
    },
    ket_selesai: [
      {
        0: "Belum ditempatkan",
        1: "Sudah",
      },
      {
        0: "light-danger",
        1: "light-success",
      },
    ],
    fieldsBarangs: [
      { key: "penyimpanan", label: "Pilih" },
      { key: "no", label: "No", sortable: true },
      { key: "nama", label: "Nama Barang", sortable: true },
      { key: "kode", label: "Kode" },
      { key: "varian", label: "Varian" },
      { key: "satuan", label: "Satuan" },
      // { key: "action", label: "#" },
    ],
    fieldsGudang: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "kode",
        label: "Barcode",
        sortable: true,
      },
      { key: "nama", label: "Nama Barang", sortable: true },
      // { key: "varian", label: "Varian", sortable: true },
      {
        key: "jumlah",
        label: "Qty",
        sortable: true,
      },
      // {
      //   key: "satuan",
      //   label: "Total Kuantitas",
      //   sortable: true,
      // },
      // { key: "blok", label: "Blok", sortable: true },
      // { key: "palet", label: "Palet", sortable: true },
      // { key: "rak", label: "Rak", sortable: true },
      // { key: "laci", label: "Laci", sortable: true },
      // {
      //   key: "lokasi",
      //   label: "Lokasi Penyimpanan",
      //   sortable: true,
      // },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      // { key: "from.nama_gudang", label: "Gudang Asal", sortable: true },
      // { key: "publish", label: "Publish?" },
      { key: "actionsCabang", label: "Aksi" },
    ],
    fieldsCabang: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "kode",
        label: "Kode",
        sortable: true,
      },
      { key: "nama", label: "Nama Barang", sortable: true },
      { key: "varian", label: "Varian", sortable: true },
      {
        key: "jumlah",
        label: "Kuantitas",
        sortable: true,
      },
      {
        key: "satuan",
        label: "Total Kuantitas",
        sortable: true,
      },
      { key: "blok", label: "Blok", sortable: true },
      { key: "palet", label: "Palet", sortable: true },
      { key: "rak", label: "Rak", sortable: true },
      { key: "laci", label: "Laci", sortable: true },
      // {
      //   key: "lokasi",
      //   label: "Lokasi Penyimpanan",
      //   sortable: true,
      // },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      // { key: "from.nama_gudang", label: "Gudang Asal", sortable: true },
      { key: "publish", label: "Publish?" },
      { key: "actionsCabang", label: "Aksi" },
    ],
    showModal: false,
    fieldsasli: null,
    fields: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "gudang.nama_gudang",
        label: "Gudang",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal",
        label: "Tanggal pengiriman",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
    published: false,
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    "form.jenis"(j) {
      if (j == 1) {
        this.form.no_invoice = this.getNewNoInvoice();
      } else {
        this.form.no_invoice = null;
      }
    },
    "form.blok_id"(blok_id) {
      this.getpalet(blok_id);
    },
    "form.palet_id"(palet_id) {
      this.getRak(palet_id);
    },
    "form.rak_id"(rak_id) {
      this.getLaci(rak_id);
    },
    perPage(value) {
      if (value) {
        this.getpenyimpananBarangs();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getpenyimpananBarangs();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getpenyimpananBarangs();
        // this.items
      }
    },
    perPageBarangs(value) {
      if (value) {
        this.getDataBarangs();
      }
    },
    // filterBarangs(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getDataBarangs();
    //   }
    // },
    currentPageBarangs(val) {
      if (val) {
        this.getDataBarangs();
        // this.items
      }
    },
  },
  methods: {
    onFilteredBarangs(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBarangs = filteredItems.length;
      this.currentPageBarangs = 1;
    },
    submitbrg() {
      this.ubah.blok_id = this.ubah.blok.id;
      this.ubah.barang_id = this.pilihbarang2.id;
      let payload = {
        id: this.ubah.id,
        blok_id: this.ubah.blok.id,
        barang_id: this.pilihbarang2.id,
        gudang_id: this.ubah.gudang_id,
        // palet_id : ,
        // rak_id : ,
        // laci_id : ,
      };

      this.$store
        .dispatch("tokopenyimpanan/save2", [payload])
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            message: "Penyimpanan Barang berhasil diubah!",
          });
          this.getpenyimpananBarangs();
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    submitpen() {
      let payload = {
        id: this.ubahpen.id,
        blok_id: this.ubahpen.blok_id,
        barang_id: this.ubahpen.barang_id,
        gudang_id: this.ubahpen.gudang_id,
      };
      if (this.ubahpen.palet_id) {
        payload.palet_id = this.ubahpen.palet_id;
      }
      if (this.ubahpen.rak_id) {
        payload.rak_id = this.ubahpen.rak_id;
      }
      if (this.ubahpen.palet_id) {
        payload.laci_id = this.ubahpen.laci_id;
      }
      this.$store
        .dispatch("tokopenyimpanan/save2", [payload])
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            message: "Penyimpanan Barang berhasil diubah!",
          });
          this.getpenyimpananBarangs();
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    ubahbrg(item) {
      this.ubah = item;
      this.showModalBrg = true;
    },
    ubahpeny(item) {
      this.ubahpen = item;
      this.ubahpen.blok_id = item.blok.id;
      this.getpalet(item.blok.id);
      this.ubahpen.palet_id = item.palet ? item.palet.id : null;
      this.ubahpen.rak_id = item.rak ? item.rak.id : null;
      this.ubahpen.laci_id = item.laci ? item.laci.id : null;
      this.showModalPen = true;
    },
    async togglePublish(item) {
      if (item.is_published) {
        try {
          await this.$store.dispatch("tokopenyimpanan/publish", {
            id: item.id,
            is_published: 1,
          });
          this.displaySuccess({
            text: "Data berhasil Dipublish",
          });
        } catch (e) {
          this.displayError(e);
        }
      } else {
        try {
          await this.$store.dispatch("tokopenyimpanan/publish", {
            id: item.id,
            is_published: 0,
          });
          this.displaySuccess({
            text: "Data berhasil Diunpublish",
          });
        } catch (e) {
          this.displayError(e);
        }
      }
    },
    add() {
      // this.resetForm();
      this.showModal = true;
    },
    coba() {
      if (this.isAdminGudang || this.isStaffGudang) {
        return (this.fieldsasli = this.fieldsGudang);
      } else if (this.isCabang) {
        return (this.fieldsasli = this.fieldsCabang);
      } else {
        this.fieldsasli = this.fields;
      }
    },
    batal(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dibatalkan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/batal", {
              pengiriman_id: item.id,
              metode: "transaksi",
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dibatalkan",
              });
              this.getpenyimpananBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async exportpengirimanBarang({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayWarning({
          text: "Harap isi tanggal awal dan akhir!",
        });
        return false;
      }

      const params = {
        tgl_awal,
        tgl_akhir,
        type_export,
      };

      try {
        this.loading = true;
        const response = await this.doExport(params);
        this.loading = false;
        await window.open(response);
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split("/");
          const filename = arrFile[arrFile.length - 1];

          await this.clearExportedFile(filename);
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    submit() {
      this.addedItems.map((item) => {
        delete item.barang;
        delete item.lokasi;
      });
      let payload = [];
      Object.assign(payload, this.addedItems);

      this.loading = true;
      this.$store
        .dispatch("tokopenyimpanan/save", payload)
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            message: "Penyimpanan Barang berhasil ditambahkan!",
          });

          setTimeout(() => {
            this.$router.push("/cabang/penyimpanan");
          }, 1000);
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    getDataGudang() {
      let coba = [this.myGudang];
      coba.map((item) => {
        item.value = item.id;
        item.text = item.nama_gudang;
      });
      // this.dataGudang = gudangs
      this.dataGudang = coba;
    },
    async getNewNoInvoice() {
      const noInvoice = await this.$store.dispatch(
        "pengiriman/getNewNoInvoice"
      );
      this.form.no_invoice = noInvoice.message.format;
    },
    add() {
      // this.resetForm();
      this.showModal = true;
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/deletepengiriman", {
              pengiriman_id: item.id,
              metode: "main",
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.getpenyimpananBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getpenyimpananBarangs() {
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        // blok_id: this.cariblok,
        // palet_id: this.caripalet,
        // rak_id: this.carirak,
        // laci_id: this.carilaci,
        // barang_id: this.caribarang,
        // kode_barang: this.carikodebarang,
        gudang_id: this.myGudang.id,
      };
      // let payload = this.isAdminGudang
      //   ? { from_gudang_id: this.myGudang.id, order: "desc" }
      //   : { order: "desc" };
      const penyimpananBarangs2 = await this.$store.dispatch(
        "tokopenyimpanan/getData",
        payload
      );
      // this.penyimpananBarangs = penyimpananBarangs;
      // this.totalRows = this.penyimpananBarangs.length;
      let items = JSON.parse(
        JSON.stringify(this.$store.state.tokopenyimpanan.datas)
      );
      let items_total = this.$store.state.tokopenyimpanan.totals;
      this.penyimpananBarangs = items;
      this.totalRows = items_total;
      this.loading = false;
    },
    async getDataBarangs() {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPageBrg = parseInt(this.perPageBarangs);
      const currentPageBrg =
        (parseInt(this.currentPageBarangs) - 1) * perPageBrg;
      const payload = {
        search: this.caribarang != null ? this.caribarang : null,
        order: "desc",
        start: currentPageBrg,
        length: this.perPageBarangs,
        // filter_by: "nama_lengkap",
        // kode: this.caribarang ? this.caribarang : "",
      };
      this.$store
        .dispatch("barang/getDataV2", payload)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM',this.items)

          let items = JSON.parse(
            JSON.stringify(this.$store.state.barang.datas)
          );
          let items_total = this.$store.state.barang.totals;
          this.dataBarangs = items;
          const barangsId = response.map((barang) => barang.id);
          // get stock
          // this.getStockBarang(barangsId)
          this.totalRowsBarangs = items_total;
          // this.$refs.table.refresh()
          // console.log('HEMMM',this.items)
          // this.$root.$emit("bv::refresh::table", "table");
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    getDatablok() {
      let params = {
        jenis: 1,
      };
      // if (this.isAdminGudang) {
      params.gudang_id = this.myGudang.id;
      // }
      this.$store.dispatch("tokoblok/getData", params).then(() => {
        let ter = JSON.parse(JSON.stringify(this.$store.state.tokoblok.datas));
        ter.map((item) => {
          item.value = item.id;
          item.text = item.blok;
        });
        this.blok_id = ter;
      });
    },
    addBarang() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let data = {};
          Object.assign(data, this.form);
          data.barang = this.pilihbarang.nama;
          data.barang_id = this.pilihbarang.id;
          data.jumlah = 0;
          // get lokasi
          const blok = this.blok_id.find((blok) => blok.value == data.blok_id);
          const palet = data.palet_id
            ? this.palet_id.find((palet) => palet.value == data.palet_id)
            : null;
          const rak = data.rak_id
            ? this.rak_id.find((rak) => rak.value == data.rak_id)
            : null;
          const laci = data.laci_id
            ? this.laci_id.find((laci) => laci.value == data.laci_id)
            : null;

          let lokasi = blok ? `Blok: ${blok.text}` : "";
          if (palet) {
            lokasi += `, Palet: ${palet.text}`;
          }
          if (rak) {
            lokasi += `, Rak: ${rak.text}`;
          }

          if (laci) {
            lokasi += `, Laci: ${laci.text}`;
          }
          data.lokasi = lokasi;
          this.addedItems.push(data);
          // this.form.blok_id = null,
          //   this.form.palet_id = null,
          //   this.form.rak_id = null,
          //   this.form.laci_id = null
        }
      });
    },
    getpalet(blok_id) {
      this.palet_id = [];
      this.$store.dispatch("tokopalet/getData", { blok_id }).then((palets) => {
        palets.forEach((palet) => {
          this.palet_id.push({
            value: palet.id,
            text: palet.palet,
          });
        });
      });
    },
    getRak(palet_id) {
      this.rak_id = [];
      this.$store.dispatch("tokorak/getData", { palet_id }).then((raks) => {
        raks.forEach((rak) => {
          this.rak_id.push({
            value: rak.id,
            text: rak.rak,
          });
        });
      });
    },
    getLaci(rak_id) {
      this.laci_id = [];
      this.$store.dispatch("tokolaci/getData", { rak_id }).then((lacis) => {
        lacis.forEach((laci) => {
          this.laci_id.push({
            value: laci.id,
            text: laci.laci,
          });
        });
      });
    },
  },
  async created() {
    this.loading = true;
    await this.getDatablok();
    await this.getpenyimpananBarangs();
    // await this.getDataGudang();
    await this.coba();
    this.loading = false;
  },
};
</script>
